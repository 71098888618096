import React,{Fragment,useCallback,useState} from 'react';
import {BulkDeleteButton,useRecordContext,Filter,SaveButton,DeleteButton,List,Datagrid,TextField,NumberField,DateField,BooleanField,EditContextProvider,SimpleForm,useEditController,FilterLiveSearch,DateInput} from 'react-admin';
import {makeStyles,Typography,IconButton,Drawer} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import {Card as MuiCard,CardContent,withStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {Route,RouteChildrenProps,useHistory} from 'react-router-dom';

import classnames from 'classnames';

import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';
import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';

import {ActiveSpan} from './Booking';
import {ReservationLastVisitedFilter} from './ReservationAside';


const Card = withStyles(theme => ({
	root: {
		[theme.breakpoints.up('sm')]: {
			order: -1, // display on the left rather than on the right of the list
			width: '15em',
			marginRight: '1em',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}))(MuiCard);

const useStyles = makeStyles(theme => ({
	listRoot: {
		display: 'flex',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'space-between',
	},
	hiddenOnSmallScreens: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	headerRow: {
		borderLeftColor: 'transparent',
		borderLeftWidth: 5,
		borderLeftStyle: 'solid',
	},
	headerCell: {
		padding: '6px 8px 6px 8px',
	},
	rowCell: {
		padding: '6px 8px 6px 8px'
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(['all'],{
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listWithDrawer: {
		marginRight: 400,
	},
	drawerPaper: {
		zIndex: 100,
	},
}));

const FilterSidebar = (props) => (
	<Card>
		<CardContent>
			<FilterLiveSearch label="Cerca per Voucher" source="cod_voucher" />
			<FilterLiveSearch label="Cerca per Nr. Ordine" source="ordine_id" />
		</CardContent>
	</Card>
);

const PostFilters = (props) => (
	<Filter {...props}>
		<DateInput label="Cerca" source="data" alwaysOn />
	</Filter>
)

const ReservationBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton
			undoable={false}
			confirmTitle={'Sei sicuro?'}
			confirmContent={'Sei sicuro di voler eliminare questo elemento?'}
			{...props} />
    </Fragment>
);

const GiornalieroSeraleSpan = ({source}) => {
	const record = useRecordContext();
	if(record && record[source]) {
		return (<span>GIORNALIERO</span>)
	} else {
		return (<span>SERALE</span>)
	}
};

export const ReservationList = (props) => {
	const [selectedRow, setSelectedRow] = useState([])
	const classes = useStyles()
	const history = useHistory();
	const handleClose = useCallback(() => {
		history.push('/reservation');
	},[history]);

	console.log(props)
	return (
		<div className={classes.listRoot}>
			<Route path="/reservation/:id">
				{({match}: RouteChildrenProps<{id: string}>) => {
					const isMatch = !!(
						match &&
						match.params &&
						match.params.id !== 'create'
					);

					return (
						<Fragment>
							<List
								aside={<FilterSidebar />}
								bulkActionButtons={<ReservationBulkActionButtons />}
								actions={false}
								perPage={25}
								sort={{field: 'data',order: 'DESC'}}
								filters={<PostFilters />}
								className={classnames(classes.list,{
									[classes.listWithDrawer]: isMatch,
								})}
								{...props}>
								<Datagrid
									rowClick={(selectedIds) => {
										setSelectedRow(selectedIds)
										return "edit"
									}}
									rowStyle={(record) => {
										let style = {};
										if(!record) {
											return style;
										}
										if(selectedRow && selectedRow.includes(record.id)) {
											style = {
												...style,
												backgroundColor: '#efefef',
											};
										}
										if(record.used === true)
											return {
												...style,
												borderLeftColor: red[500],
												borderLeftWidth: 5,
												borderLeftStyle: 'solid',
											};
										if(record.used === false)
											return {
												...style,
												borderLeftColor: yellow[500],
												borderLeftWidth: 5,
												borderLeftStyle: 'solid',
											};

										return style;
									}}
									classes={{
										headerRow: classes.headerRow,
										headerCell: classes.headerCell,
										rowCell: classes.rowCell,
									}}
									{...props}
								>
									<TextField source="id" headerClassName={classes.hiddenOnSmallScreens} cellClassName={classes.hiddenOnSmallScreens} />
									<DateField source="data" />
									<TextField source="ordine_id" />
									<TextField label="Nome" source="dati_utente.nome" />
									<TextField label="Cognome" source="dati_utente.cognome" />
									<TextField source="cod_voucher" />
									<NumberField source="quantity" />
									<GiornalieroSeraleSpan label="Giornaliero/Serale" source="giornaliero_serale" />
								</Datagrid>
							</List>
							<Drawer
								variant="persistent"
								open={isMatch}
								anchor="right"
								onClose={handleClose}
								classes={{
									paper: classes.drawerPaper,
								}}
							>
								{isMatch ? (
									<ReservationEdit
										id={match.params.id}
										onCancel={handleClose}
										resource={props.resource}
										basePath={props.basePath}
									/>
								) : null}
							</Drawer>
						</Fragment>
					);
				}}
			</Route>
		</div>
	)
}

const ReviewEditToolbar = ({
	basePath,
	handleSubmitWithRedirect,
	invalid,
	record,
	resource,
	saving,
}) => {
	const classes = useStyles();
	if(!record) return null;
	return (
		<MuiToolbar className={classes.root}>
			<Fragment>
				<AcceptButton record={record} />
				<RejectButton record={record} />
				<SaveButton
					handleSubmitWithRedirect={handleSubmitWithRedirect}
					invalid={invalid}
					saving={saving}
					redirect="list"
					submitOnEnter={true}
				/>
			</Fragment>
		</MuiToolbar>
	);
}

const useEditStyles = makeStyles(theme => ({
	root: {
		paddingTop: 40,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	form: {
		[theme.breakpoints.up('xs')]: {
			width: 400,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100vw',
			marginTop: -30,
		},
	},
	inlineField: {
		display: 'inline-block',
		width: '50%',
	},
}));

export const ReservationEdit = ({onCancel,...props}) => {
	const classes = useEditStyles();
	const controllerProps = useEditController(props);
	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6">
					Dettagli
				</Typography>
				<IconButton onClick={onCancel}>
					<CloseIcon />
				</IconButton>
			</div>
			<EditContextProvider value={controllerProps}>
				<SimpleForm
					className={classes.form}
					basePath={controllerProps.basePath}
					record={controllerProps.record}
					save={controllerProps.save}
					version={controllerProps.version}
					redirect="list"
					toolbar={<ReviewEditToolbar />}
				>
					<DateInput source="data" />
					<TextField source="ordine_id" />
					<TextField source="dati_utente.nome" />
					<TextField source="dati_utente.cognome" />
					<TextField source="token" />
					<TextField source="cod_voucher" />
					<NumberField source="quantity" />
					<GiornalieroSeraleSpan label="Giornaliero/Serale" source="giornaliero_serale" />
					<BooleanField label="Usato" source="used" />
				</SimpleForm>
			</EditContextProvider>
		</div>
	)
}
