import React,{Fragment,useCallback,useState} from 'react';
import {BulkDeleteButton,SaveButton,DeleteButton,Filter,useNotify,useRefresh,NullableBooleanInput,Pagination,useEditController,List,Edit,SimpleForm,Datagrid,TextField,NumberField,DateField,DateInput,NumberInput,BooleanInput,required,Create,useRecordContext,TopToolbar,CreateButton,useCreate} from 'react-admin';
import {Card as MuiCard,CardContent,withStyles} from '@material-ui/core';
import {makeStyles,Typography,IconButton,Drawer} from '@material-ui/core';
import {
	eachDayOfInterval,
	format
} from 'date-fns';
import {LastVisitedFilter} from "./BookingAside";
import {Route,RouteChildrenProps,useHistory} from 'react-router-dom';
import classnames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import MuiToolbar from '@material-ui/core/Toolbar';

const ListActions = (props) => (
	<TopToolbar>
		<CreateButton />
	</TopToolbar>
);

const Card = withStyles(theme => ({
	root: {
		[theme.breakpoints.up('sm')]: {
			order: -1, // display on the left rather than on the right of the list
			width: '15em',
			marginRight: '1em',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}))(MuiCard);

const useStyles = makeStyles(theme => ({
	hiddenOnSmallScreens: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	listRoot: {
		display: 'flex',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerRow: {
		borderLeftColor: 'transparent',
		borderLeftWidth: 5,
		borderLeftStyle: 'solid',
	},
	headerCell: {
		padding: '6px 8px 6px 8px',
	},
	rowCell: {
		padding: '6px 8px 6px 8px'
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(['all'],{
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listWithDrawer: {
		marginRight: 400,
	},
	drawerPaper: {
		zIndex: 100,
	},
	myRadio: {
		width: '256px!important',
	},
}));

const FilterSidebar = (props) => (
	<Card>
		<CardContent>
			<LastVisitedFilter />
		</CardContent>
	</Card>
);

const FestivoFerialeSpan = ({source}) => {
	const record = useRecordContext();
	if(record && record[source]) {
		return (<span>FESTIVO</span>)
	} else {
		return (<span>FERIALE</span>)
	}
};
export const ActiveSpan = ({source}) => {
	const record = useRecordContext();
	if(record && record[source]) {
		return <FontAwesomeIcon icon={faCheckCircle} />
	} else {
		return <FontAwesomeIcon icon={faTimesCircle} />
	}
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10,25,50,100]} {...props} />;

const PostFilters = (props) => (
	<Filter {...props}>
		<DateInput label="Cerca" source="date" alwaysOn />
	</Filter>
)

const BookingBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton
			undoable={false}
			confirmTitle={'Sei sicuro?'}
			confirmContent={'Sei sicuro di voler eliminare questo elemento?'}
			{...props} />
    </Fragment>
);

export const BookingList = (props) => {
	const [selectedRow, setSelectedRow] = useState([])
	const classes = useStyles()
	const history = useHistory();
	const handleClose = useCallback(() => {
		history.push('/booking');
	},[history]);

	return (
		<div className={classes.listRoot}>
			<Route path="/booking/:id">
				{({match}: RouteChildrenProps<{id: string}>) => {
					const isMatch = !!(
						match &&
						match.params &&
						match.params.id !== 'create'
					);
					return (
						<Fragment>
							<List
								bulkActionButtons={<BookingBulkActionButtons />}
								pagination={<PostPagination />}
								actions={<ListActions />}
								filters={<PostFilters />}

								perPage={25}
								sort={{field: 'date',order: 'DESC'}}
								className={classnames(classes.list,{
									[classes.listWithDrawer]: isMatch,
								})}
								{...props}
							>
								<Datagrid
									rowClick={(selectedIds) => {
										setSelectedRow(selectedIds)
										return "edit"
									}}
									rowStyle={(record) => {
										if(selectedRow && selectedRow.includes(record.id)) {
											return {
												backgroundColor: '#efefef',
											}
										}
									}}
									classes={{
										headerRow: classes.headerRow,
										headerCell: classes.headerCell,
										rowCell: classes.rowCell,
									}}
									{...props}
								>
									<TextField source="id" headerClassName={classes.hiddenOnSmallScreens} cellClassName={classes.hiddenOnSmallScreens} />
									<DateField source="date" />
									<NumberField source="disponibilita_giornaliera" />
									<NumberField source="disponibilita_serale" />
									<FestivoFerialeSpan source="festivo_feriale" />
									<ActiveSpan source="active" />
								</Datagrid>
							</List>
							<Drawer
								variant="persistent"
								open={isMatch}
								anchor="right"
								onClose={handleClose}
								classes={{
									paper: classes.drawerPaper,
								}}
							>
								{isMatch ? (
									<BookingEdit
										id={match.params.id}
										onCancel={handleClose}
										resource={props.resource}
										basePath={props.basePath}
									/>
								) : null}
							</Drawer>
						</Fragment>
					)
				}}
			</Route>
		</div>
	)
}

export const BookingCreate = (props) => {
	const [create,{loading}] = useCreate();
	const classes = useStyles()

	const notify = useNotify();
	const refresh = useRefresh();
	const onSuccess = () => {
		notify(`Changes saved`)
		refresh();
	};

	const handleSave = (values,basePath,redirect) => {
		if(!!values.data_end) {
			const days = eachDayOfInterval({
				start: new Date(values.data_start),
				end: new Date(values.data_end)
			})
			days.forEach((it) => {
				values.date = format(it,'yyyy-MM-dd')
				create('booking',values,basePath)
			})
		} else {
			values.date = values.data_start
			create('booking',values,basePath)
		}
		notify(`Modifiche effettuate`, 'info')
	}

	return (<Create onSuccess={onSuccess} {...props}>
		<SimpleForm save={handleSave} redirect="booking">
			<DateInput label="Data Disponibilità Iniziale" source="data_start" validate={required()} />
			<DateInput label="Data Disponibilità Finale" source="data_end" validate={required()} />
			<NumberInput label="Posti disponibili Giornalieri" source="disponibilita_giornaliera" validate={required()}></NumberInput>
			<NumberInput label="Posti disponibili Serali" source="disponibilita_serale" validate={required()}></NumberInput>
			<NullableBooleanInput
				className={classes.myRadio}
				label="Feriale/Festivo"
				source="festivo_feriale"
				falseLabel="Feriale"
				trueLabel="Festivo"
				validate={required()}
			/>
			<BooleanInput label="Attivo" source="active"></BooleanInput>
		</SimpleForm>
	</Create>)
}

const useEditStyles = makeStyles(theme => ({
	root: {
		paddingTop: 40,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	form: {
		[theme.breakpoints.up('xs')]: {
			width: 400,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100vw',
			marginTop: -30,
		},
	},
	inlineField: {
		display: 'inline-block',
		width: '50%',
	},
}));

const BookingEditToolbar = ({
	basePath,
	handleSubmitWithRedirect,
	invalid,
	record,
	resource,
	saving,
}) => {
	const classes = useStyles();
	if(!record) return null;
	return (
		<MuiToolbar className={classes.root}>
			<Fragment>
				<SaveButton
					handleSubmitWithRedirect={handleSubmitWithRedirect}
					invalid={invalid}
					saving={saving}
					redirect="list"
					submitOnEnter={true}
				/>
				<DeleteButton
					basePath={basePath}
					record={record}
					resource={resource}
					undoable={false}
					confirmTitle={'Sei sicuro?'}
					confirmContent={'Sei sicuro di voler eliminare questo elemento?'}
				/>
			</Fragment>
		</MuiToolbar>
	);
}

export const BookingEdit = ({onCancel,...props}) => {
	const classes = useEditStyles();
	const controllerProps = useEditController(props);
	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6">
					Dettagli
				</Typography>
				<IconButton onClick={onCancel}>
					<CloseIcon />
				</IconButton>
			</div>
			<Edit {...props}>
				<SimpleForm
					className={classes.form}
					basePath={controllerProps.basePath}
					record={controllerProps.record}
					save={controllerProps.save}
					version={controllerProps.version}
					redirect="list"
					toolbar={<BookingEditToolbar/>}>
					<DateInput label="Data Disponibilità" source="date" />
					<NumberInput label="Posti disponibili Giornalieri" source="disponibilita_giornaliera" validate={required()}></NumberInput>
					<NumberInput label="Posti disponibili Serali" source="disponibilita_serale" validate={required()}></NumberInput>
					<NullableBooleanInput
						className={classes.myRadio}
						label="Feriale/Festivo"
						source="festivo_feriale"
						falseLabel="Feriale"
						trueLabel="Festivo"
						validate={required()}
					/>
					<BooleanInput label="Attivo" source="active" ></BooleanInput>
				</SimpleForm>
			</Edit>
		</div>
	)
}


