import React,{useEffect,useState} from 'react';
import buildHasuraProvider from 'ra-data-hasura';
import {Admin,Resource,Login,Title,Notification,Layout,} from 'react-admin';
import {InMemoryCache,ApolloClient} from '@apollo/client';
import {makeStyles,createMuiTheme} from '@material-ui/core';
import authProvider from './components/authProvider';
import {BookingCreate,BookingEdit,BookingList} from './components/Booking';
import {ReservationList} from './components/Reservation';
import LoginPage from './components/LoginPage';

const App = () => {
	const myClientWithAuth = new ApolloClient({
		uri: process.env.REACT_APP_HASURA_BACKEND,
		cache: new InMemoryCache(),
		headers: {
			'x-hasura-admin-secret': `DrTxukA2zc9YMKhGe3Dc5ttPJumJpCvN`,
			'content-type': 'application/json'
		},
	});
	const myClientWithoutAuth = new ApolloClient({
		uri: process.env.REACT_APP_HASURA_BACKEND,
		cache: new InMemoryCache(),
	});

	const [resolvedDataProvider,setResolvedDataProvider] = useState();

	useEffect(() => {
		if(!resolvedDataProvider) {
			(async () => {
				buildHasuraProvider({client: myClientWithAuth}).then((dp) => {
					setResolvedDataProvider(() => dp);
				}).catch((e) => {
					buildHasuraProvider({client: myClientWithoutAuth}).then((dp) => setResolvedDataProvider(() => dp))
				})
			})()
		}
	});


	if(!resolvedDataProvider) {
		return <div>Loading...</div>;
	}

	const myTheme = createMuiTheme({
		overrides: {
			MuiAppBar: {
				colorSecondary: {
					'background-color': '#A08F7C'
				}
			},
			MuiButton: {
				textPrimary: {
					color: '#4C3920'
				}
			},
		}
	})

	return (
		<Admin theme={myTheme} loginPage={LoginPage} authProvider={authProvider} dataProvider={resolvedDataProvider}>
			<Resource options={{label: 'Disponibilità giornaliere'}} name="booking" list={BookingList} create={BookingCreate} />
			<Resource options={{label: 'Prenotazioni'}} name="reservation" list={ReservationList} />
		</Admin>
	);
};

export default App;
