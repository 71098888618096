import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { useTranslate, useUpdate, useNotify, useRedirect, Confirm } from 'react-admin';

/**
 * This custom button demonstrate using a custom action to update data
 */
const RejectButton = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();

    const [reject, { loading }] = useUpdate(
        'reservation',
        record.id,
        { used: false},
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(
                    'resources.reviews.notification.rejected_success',
                    'info',
                    {},
                    true
                );
                redirectTo('/reservation');
            },
            onFailure: () => {
                notify(
                    'resources.reviews.notification.rejected_error',
                    'warning'
                );
            },
        }
    );

	const [open, setOpen] = React.useState(false);
	const handleDialogClose = () => setOpen(false);
	const handleConfirm = () => {
		reject()
		notify('Voucher validato');
		setOpen(false);
	};
	const handleSendItemsToUpdate = (props) => {
		setOpen(true);
	}
    return record && record.used === true ? (
		<React.Fragment>
		<Confirm
			isOpen={open}
			title="Valida Voucher"
			content="Sei sicuro di voler validare questo voucher?"
			onConfirm={handleConfirm}
			onClose={handleDialogClose}
		/>
		<Button
			variant="outlined"
			color="primary"
			size="small"
			onClick={() => handleSendItemsToUpdate()}
			disabled={loading}
		>
			Valida
		</Button>

	</React.Fragment>
	) : (
		<span />
	);
};

RejectButton.propTypes = {
    record: PropTypes.any,
};

export default RejectButton;