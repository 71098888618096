const authProvider = {
	logout: () => {
		localStorage.removeItem('auth');
		return Promise.resolve();
	},
	login: ({username,password}) => {
		const request = new Request('https://flow.lessful.com/webhook/relilax/auth/login',{
			method: 'POST',
			body: JSON.stringify({username,password}),
			headers: new Headers({'Content-Type': 'application/json'}),
		})
		return fetch(request).then(response => {
			if(response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText);
			}
			return response.json();
		}).then(({token}) => {
			localStorage.setItem('auth',token);
		});
	},
	checkError: (error) => {
		const status = error.status;
		if(status === 401 || status === 403) {
			localStorage.removeItem('auth');
			return Promise.reject({message: false})
		}
		// other error code (404, 500, etc): no need to log out
		return Promise.resolve();
	},
	checkAuth: () => !!localStorage.getItem('auth') && localStorage.getItem('auth').length === 32
		? Promise.resolve()
		: Promise.reject({message: false}),
	getPermissions: () => Promise.resolve()
}

export default authProvider