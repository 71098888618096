import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import {useTranslate,useUpdate,useNotify,useRedirect,SaveButton,Confirm} from 'react-admin';
import axios from 'axios'
import qs from 'qs';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({record}) => {
	const translate = useTranslate();
	const notify = useNotify();
	const redirectTo = useRedirect();

	const [approve, {loading}] = useUpdate(
		'reservation',
		record.id,
		{used: true},
		record,
		{
			undoable: true,
			onSuccess: () => {
				record.cod_voucher.split(',').forEach((it) => {
					axios.post(
						'https://staging.relilax.net/wp-json/api/v1/redeem',
						qs.stringify({
							voucode: it.trim(),
							order: record.ordine_id,
							auth: 'qpncTC49hddRxx93'
						}),
						{ headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
					)
				})
				notify(
					'resources.reviews.notification.approved_success',
					'info',
					{},
					true
				);
				redirectTo('/reservation');
			},
			onFailure: () => {
				notify(
					'resources.reviews.notification.approved_error',
					'warning'
				);
			},
		}
	);

	const [open, setOpen] = React.useState(false);
	const handleDialogClose = () => setOpen(false);
	const handleConfirm = () => {
		approve()
		notify('Voucher riscattato');
		setOpen(false);
	};
	const handleSendItemsToUpdate = (props) => {
		setOpen(true);
	}
	return record && record.used === false ? (
	<React.Fragment>
		<Confirm
			isOpen={open}
			title="Riscatta Voucher"
			content="Sei sicuro di voler riscattare questo voucher?"
			onConfirm={handleConfirm}
			onClose={handleDialogClose}
		/>
		<Button
			variant="outlined"
			color="primary"
			size="small"
			onClick={() => handleSendItemsToUpdate()}
			disabled={loading}
		>
			Riscatta
		</Button>

	</React.Fragment>
	) : (
		<span />
	);
};

AcceptButton.propTypes = {
	record: PropTypes.any,
};

export default AcceptButton;