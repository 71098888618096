import React from 'react'
import {makeStyles,Grid} from '@material-ui/core';
import {Notification,defaultTheme} from 'ra-ui-materialui'
import {Card} from '@material-ui/core'
import LoginForm from './LoginForm'

const useStyles = makeStyles(theme => ({
	left: {
		background: 'url(img/sfondo-half.png)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	right: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		height: '1px',
		alignItems: 'center',
		justifyContent: 'center',
		borderLeft: '1px solid #000'
	},
	card: {
		minWidth: 500,
		maxWidth: 500,
		marginTop: '0 auto',
		boxShadow: 'unset',
		textAlign: 'center'
	},
	powered: {
		bottom: '15px',
		position: 'absolute',
		fontSize: '13px',
		fontWeight: '400',
		color: '#4A422C'
	},
	poweredLink: {
		color: '#4A422C',
		textDecoration: 'none',
		fontWeight: '600'
	}
}))
const LoginPage = ({children, ...props}) => {

	const classes = useStyles()

	return (
		<Grid container spacing={0}>
			<Grid item xs={6} className={classes.left}></Grid>
			<Grid item xs={6} className={classes.right}>
				<Card className={classes.card}>
					{children}
				</Card>
				<div className={classes.powered}>
					<span>Powered By <a className={classes.poweredLink} href="https://internetimage.it" target="_blank" rel="noreferrer">Internetimage.it</a></span>
				</div>
			</Grid>
		</Grid>
	)

}

LoginPage.defaultProps = {
	theme: defaultTheme,
	children: <LoginForm />,
	notification: Notification,
}

export default LoginPage